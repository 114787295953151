import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Tooltip, Stack } from '@mui/material';
//
import { fileData, fileFormat, fileThumb } from './utils';
import DownloadButton from './DownloadButton';
export default function FileThumbnail({ file, tooltip, imageView, onDownload, sx, imgSx, }) {
    const { name = '', path = '', preview = '' } = fileData(file);
    const format = fileFormat(path || preview);
    const renderContent = format === 'image' && imageView ? (_jsx(Box, { component: "img", src: preview, sx: {
            width: 1,
            height: 1,
            flexShrink: 0,
            objectFit: 'cover',
            ...imgSx,
        } })) : (_jsx(Box, { component: "img", src: fileThumb(format), sx: {
            width: 32,
            height: 32,
            flexShrink: 0,
            ...sx,
        } }));
    if (tooltip) {
        return (_jsx(Tooltip, { title: name, children: _jsxs(Stack, { flexShrink: 0, component: "span", alignItems: "center", justifyContent: "center", sx: {
                    width: 'fit-content',
                    height: 'inherit',
                }, children: [renderContent, onDownload && _jsx(DownloadButton, { onDownload: onDownload })] }) }));
    }
    return (_jsxs(_Fragment, { children: [renderContent, onDownload && _jsx(DownloadButton, { onDownload: onDownload })] }));
}
